import React from 'react';
import { styled } from '@glitz/react';
import { minMediumMediaQuery, pixelsToUnit } from 'Shared/Style';
import Button, { Appearance as ButtonAppearance } from 'Shared/Button';

type PropType = {
  buttonCaption: string;
  heading: string;
  redirectLink: string;
};

export default function AccountPanel(props: PropType) {
  return (
    <PanelContainer>
      <H1>{props.heading}</H1>
      {!!props.redirectLink && (
        <ActionButton to={props.redirectLink} appearance={ButtonAppearance.Secondary}>
          {props.buttonCaption}
        </ActionButton>
      )}
    </PanelContainer>
  );
}

const H1 = styled.h1({
  fontWeight: 'normal',
  marginBottom: pixelsToUnit(21),
  textAlign: 'center',
  [minMediumMediaQuery]: {
    textAlign: 'left',
  },
});

const PanelContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: pixelsToUnit(70),
  paddingTop: pixelsToUnit(28),
  [minMediumMediaQuery]: {
    alignItems: 'flex-start',
    padding: {
      x: pixelsToUnit(26),
      y: pixelsToUnit(28),
    },
  },
});

const ActionButton = styled(Button, {
  letterSpacing: pixelsToUnit(1),
  padding: {
    x: pixelsToUnit(60),
    y: 0,
  },
  [minMediumMediaQuery]: {
    marginTop: pixelsToUnit(20),
  },
});
